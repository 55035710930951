<template>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="card-body">
        <div class="card card-secondary card-outline col-md-12">
          <div class="card-header">
            <h3 class="card-title">
              <i class="fas fa-paste"></i>
              Agregar Empresas
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="empresa">Empresa</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="empresa"
                    label="razon_social"
                    :options="listasForms.empresas"
                    :disabled="
                      conductoresHasEmpresas.length > 0 || $parent.opcion == 1
                    "
                    @input="selectEmpresa()"
                  ></v-select>
                </div>
              </div>
              <div class="col-md-2">
                <br />
                <button
                  class="btn btn-dark col-md-12 mt-1"
                  @click="saveEmpresa()"
                  id="asoc_empresa"
                  style="display: none"
                  :disabled="$parent.opcion == 1"
                >
                  <i class="fas fa-save"></i><br />
                </button>
              </div>
              <div class="card-body p-0">
                <table
                  id="empresas"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>Empresa</th>
                      <th style="width: 20px">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in conductoresHasEmpresas" :key="data.id">
                      <td v-text="data.razon_social"></td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          @click="
                            destroyEmpresa(data.id, data.pivot.conductor_id)
                          "
                          :disabled="$parent.opcion == 1"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import $ from "jquery";

export default {
  name: "ConductorEmpresa",
  components: {
    vSelect,
  },
  data() {
    return {
      empresa: null,
      formEmpresa: {},
      conductoresHasEmpresas: {},
      listasForms: {
        empresas: [],
      },
    };
  },
  validations: {
    formEmpresa: {
      empresa_id: {
        required,
      },
    },
  },
  methods: {
    selectEmpresa() {
      this.formEmpresa.empresa_id = this.empresa.id;
      $("#asoc_empresa").css("display", "block");
    },

    getEmpresa() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getConductoresHasEmpresas() {
      axios
        .get("/api/admin/conductores/" + this.$parent.form.id)
        .then((response) => {
          this.conductoresHasEmpresas = response.data[0].empresas;
        });
    },

    saveEmpresa() {
      this.formEmpresa.conductor_id = this.$parent.form.id;
      this.formEmpresa.accion = true;
      axios({
        method: "PUT",
        url: "/api/admin/conductores/assingOrRevokeEmpresa",
        data: this.formEmpresa,
      })
        .then(() => {
          this.formEmpresa = {};
          this.empresa = {};
          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.getConductoresHasEmpresas();
          $("#asoc_empresa").css("display", "none");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroyEmpresa(idEmpresa, idConductor) {
      this.$swal({
        title: "Esta seguro de eliminar esta empresa?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios({
            method: "PUT",
            url: "/api/admin/conductores/assingOrRevokeEmpresa",
            data: {
              empresa_id: idEmpresa,
              conductor_id: idConductor,
              accion: false,
            },
          }).then(() => {
            this.getConductoresHasEmpresas();
            this.$swal({
              icon: "success",
              title: "Se elimino la empresa exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },
  mounted() {
    this.getEmpresa();
    this.getConductoresHasEmpresas();
  },
};
</script>
